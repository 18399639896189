import {keys, find, forEach} from 'lodash';

import {
  DEFAULT_TRANSFORM_REGEX, DEFAULT_FF_TRANSFORM_REGEX, DEFAULT_GET_STATS_FILTER
} from '../constants';
import BrowserDetection from '../common/browser-detection';

const {isBrowser} = BrowserDetection();

const StatsTransformer = {
  isFF: isBrowser('firefox')
};

// convert the ids from the parsed stats objects into understandable keys
StatsTransformer.simplify = (stat) => find(keys(StatsTransformer.isFF ? DEFAULT_FF_TRANSFORM_REGEX : DEFAULT_TRANSFORM_REGEX), (key) => {
  let value;

  if (StatsTransformer.isFF) {
    // FF stats are formatted poorly so we have to take what we can get
    value = (DEFAULT_FF_TRANSFORM_REGEX[key].regex.test(`${stat.type}${stat.kind ? `_${stat.kind}` : ''}_${stat.id}`) ? key : false);
  }
  else {
    // no other parameters necessary besides id
    value = (DEFAULT_TRANSFORM_REGEX[key].regex.test(stat.id) ? key : false);
  }

  if (value) {
    // others were included but have a value that exclude them from the list because of duplicates that weren't chosen
    if (DEFAULT_TRANSFORM_REGEX[value].decider) {
      if (stat[DEFAULT_TRANSFORM_REGEX[value].decider] !== DEFAULT_TRANSFORM_REGEX[value].selector) {
        value = false;
      }

      return value;
    }
    // // some types need to check against a type field as well
    if (DEFAULT_TRANSFORM_REGEX[value].profiler) {
      const mapStatToReadableType = stat[DEFAULT_TRANSFORM_REGEX[value].profiler.type];

      if (!((mapStatToReadableType && mapStatToReadableType.includes(DEFAULT_TRANSFORM_REGEX[value].profiler.value)) &&
      (value.toLowerCase().includes(DEFAULT_TRANSFORM_REGEX[value].profiler.value)))) {
        value = false;

        return value;
      }
    }
  }

  return value;
});

// parse the RTCStatsReport, extracting only the data we care about
StatsTransformer.parse = (report) => {
  const target = {};

  // from the stats object
  // get the rtc stats report
  if (report && report.forEach && report.size > 0) {
    report.forEach((stat) => {
      // each report has internal data
      if (stat && stat.type) {
        // pull only certain types
        if (DEFAULT_GET_STATS_FILTER.types.includes(stat.type)) {
          // insert into the target the statistic mapped to it's statical id
          target[stat.id] = stat;
        }
      }
    });
  }

  return target;
};

StatsTransformer.convert = (parsed, options) => {
  // supply your own simplification function (rather than id as the key)
  if (options.simplifyFn) {
    return options.simplifyFn(parsed);
  }
  // else use ours that stores things like rtcOutAudio, rtpInVideo, etc
  const target = {};

  forEach(parsed, (stat) => {
    const key = StatsTransformer.simplify(stat);

    if (key) {
      target[key] = stat;
    }
  });

  return target;
};

// transform the RTCStatsReport into a much more readable, processable format
StatsTransformer.transform = (report, options = {simplifyFn: undefined}) => {
  let data;
  // first parse it and cut out not necessary data
  const parsed = StatsTransformer.parse(report);

  // either convert the data or store each stat with it's id mapped as the key to the stat object itself
  if (DEFAULT_TRANSFORM_REGEX) {
    data = StatsTransformer.convert(parsed, options);
  }
  else {
    data = parsed;
  }

  return data;
};
export default StatsTransformer;
