import {
  STATS,
  MQA_STATS
} from '../constants';
import BrowserDetection from '../common/browser-detection';

const {isBrowser} = BrowserDetection();

/**
 * @description MQAProcessor handles interval data for MQA
 * @export
 * @class MQAProcessor
 */
class MQAProcessor {
  /**
     * @constructor
     * @public
     * @memberof MQAProcessor
     */
  constructor() {
    this.data = {
      videoReceive: [],
      audioTransmit: [],
      audioReceive: [],
      videoTransmit: []
    };
    this.intervalNumber = 1;
  }

  /**
   * @param {String} id
   * @param {Array<WebRTCData>} interval - a slice of metrics history
   * @returns {undefined}
   * @memberof MQAProcessor
   */
  process(id, interval) {
    let rtcCandidatePair, rtcOutVideo, rtpOutVideo; // TODO: , rtcInVideo, rtpInVideo, rtcOutAudio, rtcInAudio, rtpInAudio, rtpOutAudio; // TODO:
    let vsTransmit;

    const {
      videoReceive, audioTransmit, audioReceive, videoTransmit
    } = this.data;

    const sumValue = interval[0]; // the head is the last interval value, webRTC spec has some values automatically summed

    if (sumValue) {
      rtcCandidatePair = sumValue.rtcCandidatePair;
    }

    switch (id) {
      case STATS.AUDIO_SENDER_ID:
        audioTransmit.push();
        break;
      case STATS.AUDIO_RECEIVER_ID:
        audioReceive.push();
        break;
      case STATS.VIDEO_SENDER_ID:
        videoTransmit.push();
        break;
      case STATS.VIDEO_RECEIVER_ID:
        videoReceive.push();
        break;
      case STATS.SHARE_SENDER_ID:
        if (sumValue) {
          rtcOutVideo = sumValue.rtcOutVideo;
          rtpOutVideo = sumValue.rtpOutVideo;
        }
        vsTransmit = {...MQA_STATS.DEFAULT_SHARE_SENDER_STATS};
        if (isBrowser('firefox')) {
          vsTransmit.common.remoteLossRate = rtpOutVideo ? rtpOutVideo.pliCount / (interval.length * this.intervalNumber) : 0;
          vsTransmit.common.rtpPackets = rtpOutVideo ? rtpOutVideo.packetsSent / (interval.length * this.intervalNumber) : 0;
          vsTransmit.streams[0].common.transmittedFrameRate = rtcOutVideo ? rtcOutVideo.framesEncoded / (interval.length * this.intervalNumber) : 0;
          vsTransmit.streams[0].common.rtpPackets = rtpOutVideo ? rtpOutVideo.packetsSent / (interval.length * this.intervalNumber) : 0;
        }
        else {
          vsTransmit.common.availableBitRate = rtcCandidatePair ? rtcCandidatePair.availableOutgoingBitrate : 0;
          vsTransmit.common.remoteLossRate = rtpOutVideo ? rtpOutVideo.pliCount / (interval.length * this.intervalNumber) : 0;
          vsTransmit.common.roundTripTime = rtcCandidatePair ? rtcCandidatePair.totalRoundTripTime / (interval.length * this.intervalNumber) : 0;
          vsTransmit.common.rtpPackets = rtpOutVideo ? rtpOutVideo.packetsSent / (interval.length * this.intervalNumber) : 0;
          vsTransmit.streams[0].common.rtpPackets = rtpOutVideo ? rtpOutVideo.packetsSent / (interval.length * this.intervalNumber) : 0;
          vsTransmit.streams[0].common.transmittedBitrate = rtcCandidatePair ? rtcCandidatePair.availableOutgoingBitrate : 0;
          vsTransmit.streams[0].common.transmittedFrameRate = rtcOutVideo ? rtcOutVideo.framesSent / (interval.length * this.intervalNumber) : 0;
          vsTransmit.streams[0].transmittedHeight = rtcOutVideo ? rtcOutVideo.frameHeight : 0;
          vsTransmit.streams[0].transmittedKeyFrames = rtcOutVideo ? rtcOutVideo.hugeFramesSent : 0;
          vsTransmit.streams[0].transmittedWidth = rtcOutVideo ? rtcOutVideo.frameWidth : 0;
        }
        videoTransmit.push(vsTransmit);
        break;
      default:
        break;
    }
    this.data.intervalMetadata = this.data.intervalMetadata || {...MQA_STATS.intervalMetadata};
  }

  /**
   * get the data payload for media quality events after they all have been processed
   * wait to call this until after you have all the data from the interval you want
   * this method clears the data as a side effect
   * @returns {Object}
   * @memberof MQAProcessor
   */
  getData() {
    this.intervalNumber += 1;

    const payload = {...this.data, intervalNumber: this.intervalNumber};

    this.data = {
      videoReceive: [],
      audioTransmit: [],
      audioReceive: [],
      videoTransmit: []
    };

    return payload;
  }
}

export default MQAProcessor;
