import {ERROR_DICTIONARY} from '../../constants';

/**
 * Extended Error object for general parameter errors
 */
export default class ParameterError extends Error {
  /**
  *
  * @constructor
  * @param {String} [message]
  * @param {Object} [error]
  */
  constructor(message = ERROR_DICTIONARY.PARAMETER.MESSAGE, error = null) {
    super(message);

    if (Error.captureStackTrace) {
      Error.captureStackTrace(this, ParameterError);
    }

    this.name = ERROR_DICTIONARY.PARAMETER.NAME;
    this.sdkMessage = ERROR_DICTIONARY.PARAMETER.MESSAGE;
    this.error = error;

    this.code = ERROR_DICTIONARY.PARAMETER.CODE;
  }
}
