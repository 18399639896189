
import {HTTP_VERBS, _CONVERSATION_URL_} from '../constants';

import MeetingInfoUtil from './utilv2';

const PASSWORD_ERROR_DEFAULT_MESSAGE = 'Password required. Call fetchMeetingInfo() with password argument';
const CAPTCHA_ERROR_DEFAULT_MESSAGE = 'Captcha required. Call fetchMeetingInfo() with captchaInfo argument';
const ADHOC_MEETING_DEFAULT_ERROR = 'Failed starting the adhoc meeting, Please contact support team ';

/**
 * Error to indicate that wbxappapi requires a password
 */
export class MeetingInfoV2PasswordError extends Error {
  /**
    *
    * @constructor
    * @param {Number} [wbxAppApiErrorCode]
    * @param {Object} [meetingInfo]
    * @param {String} [message]
    */
  constructor(wbxAppApiErrorCode, meetingInfo, message = PASSWORD_ERROR_DEFAULT_MESSAGE) {
    super(`${message}, code=${wbxAppApiErrorCode}`);
    this.name = 'MeetingInfoV2PasswordError';
    this.sdkMessage = message;
    this.stack = (new Error()).stack;
    this.wbxAppApiCode = wbxAppApiErrorCode;
    this.meetingInfo = meetingInfo;
  }
}

/**
 * Error generating a adhoc space meeting
 */
export class MeetingInfoV2AdhocMeetingError extends Error {
  /**
    *
    * @constructor
    * @param {Number} [wbxAppApiErrorCode]
    * @param {String} [message]
    */
  constructor(wbxAppApiErrorCode, message = ADHOC_MEETING_DEFAULT_ERROR) {
    super(`${message}, code=${wbxAppApiErrorCode}`);
    this.name = 'MeetingInfoV2AdhocMeetingError';
    this.sdkMessage = message;
    this.stack = (new Error()).stack;
    this.wbxAppApiCode = wbxAppApiErrorCode;
  }
}


/**
   * Error to indicate that preferred webex site not present to start adhoc meeting
   */
export class MeetingInfoV2CaptchaError extends Error {
  /**
    *
    * @constructor
    * @param {Number} [wbxAppApiErrorCode]
    * @param {Object} [captchaInfo]
    * @param {String} [message]
    */
  constructor(wbxAppApiErrorCode, captchaInfo, message = CAPTCHA_ERROR_DEFAULT_MESSAGE) {
    super(`${message}, code=${wbxAppApiErrorCode}`);
    this.name = 'MeetingInfoV2PasswordError';
    this.sdkMessage = message;
    this.stack = (new Error()).stack;
    this.wbxAppApiCode = wbxAppApiErrorCode;
    this.isPasswordRequired = wbxAppApiErrorCode === 423005;
    this.captchaInfo = captchaInfo;
  }
}

/**
   * @class MeetingInfo
   */
export default class MeetingInfoV2 {
  /**
     *
     * @param {WebexSDK} webex
     */
  constructor(webex) {
    this.webex = webex;
  }

  /**
     * converts hydra id into conversation url and persons Id
     * @param {String} destination one of many different types of destinations to look up info for
     * @param {String} [type] to match up with the destination value
     * @returns {Promise} destination and type
     * @public
     * @memberof MeetingInfo
     */
  fetchInfoOptions(destination, type = null) {
    return MeetingInfoUtil.getDestinationType({
      destination,
      type,
      webex: this.webex
    });
  }

  /**
     * Creates adhoc space meetings for a space by fetching the conversation infomation
     * @param {String} conversationUrl conversationUrl to start adhoc meeting on
     * @returns {Promise} returns a meeting info object
     * @public
     * @memberof MeetingInfo
     */
  async createAdhocSpaceMeeting(conversationUrl) {
    if (!this.webex.meetings.preferredWebexSite) {
      throw Error('No preferred webex site found');
    }
    const getInvitees = (particpants = []) => {
      const invitees = [];

      if (particpants) {
        particpants.forEach((participant) => {
          invitees.push({
            email: participant.emailAddress,
            ciUserUuid: participant.entryUUID
          });
        });
      }

      return invitees;
    };

    return this.webex.internal.conversation.get(
      {url: conversationUrl},
      {includeParticipants: true, disableTransform: true}
    )
      .then((conversation) => {
        const body = {
          title: conversation.displayName,
          spaceUrl: conversation.url,
          keyUrl: conversation.encryptionKeyUrl,
          kroUrl: conversation.kmsResourceObjectUrl,
          invitees: getInvitees(conversation.participants?.items)
        };

        const uri = this.webex.meetings.preferredWebexSite ?
          `https://${this.webex.meetings.preferredWebexSite}/wbxappapi/v2/meetings/spaceInstant` : '';

        return this.webex.request({
          method: HTTP_VERBS.POST,
          uri,
          body
        })
          .catch((err) => {
            throw new MeetingInfoV2AdhocMeetingError(err.body?.code, err.body?.message);
          });
      });
  }

  /**
     * Fetches meeting info from the server
     * @param {String} destination one of many different types of destinations to look up info for
     * @param {String} [type] to match up with the destination value
     * @param {String} password
     * @param {Object} captchaInfo
     * @param {String} captchaInfo.code
     * @param {String} captchaInfo.id
     * @returns {Promise} returns a meeting info object
     * @public
     * @memberof MeetingInfo
     */
  async fetchMeetingInfo(destination, type = null, password = null, captchaInfo = null) {
    const destinationType = await MeetingInfoUtil.getDestinationType({
      destination,
      type,
      webex: this.webex
    });

    if (destinationType.type === _CONVERSATION_URL_ && this.webex.config.meetings.experimental.enableAdhocMeetings) {
      return this.createAdhocSpaceMeeting(destinationType.destination);
    }

    const body = await MeetingInfoUtil.getRequestBody({...destinationType, password, captchaInfo});

    const options = {
      method: HTTP_VERBS.POST,
      service: 'webex-appapi-service',
      resource: 'meetingInfo',
      body
    };

    const directURI = await MeetingInfoUtil.getDirectMeetingInfoURI(destinationType);

    if (directURI) options.directURI = directURI;

    return this.webex.request(options)
      .catch((err) => {
        if (err?.statusCode === 403) {
          throw new MeetingInfoV2PasswordError(err.body?.code, err.body?.data?.meetingInfo);
        }
        if (err?.statusCode === 423) {
          throw new MeetingInfoV2CaptchaError(err.body?.code, {
            captchaId: err.body.captchaID,
            verificationImageURL: err.body.verificationImageURL,
            verificationAudioURL: err.body.verificationAudioURL,
            refreshURL: err.body.refreshURL
          });
        }
        throw err;
      });
  }
}

